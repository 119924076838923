import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ABhaServices } from "../../redux/services/index";
import { NavLink } from "react-router-dom";

const CreateAbhaAddress = ({onNavigate}) => {
  const dispatch = useDispatch();

  const createabha = (data) => {
      dispatch(ABhaServices.CreateAbhaAddressApi(data, onNavigate));
  };
  const [abhaNumber, setAbhaNumber] = useState("");
  const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(true);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setAbhaNumber(inputValue);
    setIsVerifyButtonDisabled(inputValue.length < 1);
  };

  const handleSubmit = () => {
    console.log("Form submitted with phone number:", abhaNumber);
    onNavigate("downloadabha");
    createabha({ abhaAddress: abhaNumber });
  };

  return (
    <>
      <div className=" main mt-4">
        <div
          className="wrapper m-auto lg:w-[40%] md:w-[50%] w-[90%] border-0"
          id="EnterAbha"
        >
          <h1 className=" text-[32px] font-semibold">
            Please Enter Your 14 digit ABHA Number/ABHA address{" "}
          </h1>

          <div className="form-group form-group-style customer-phone pt-5 flex items-center relative">
            <input
              value={abhaNumber}
              onChange={handleInputChange}
              className=" w-[100%] form-control number ng-pristine ng-invalid is-invalid ng-touched"
              type="text"
              id="CustomerPhone"
              placeholder="Enter Your ABHA Number/ABHA address"
              name="number"
              maxlength="14"
              formcontrolname="CustomerPhone"
            />
            <div className="message-box text-white bg-[#848484] absolute right-0">@abdm</div>
          </div>
          <NavLink>
            <div className=" pt-5">
              <button
                className={`w-[100%] py-3 rounded-lg ${
                  isVerifyButtonDisabled
                    ? "bg-gray-400 text-white cursor-not-allowed"
                    : "bg-[#065885] text-white"
                }`}
                disabled={isVerifyButtonDisabled}
                onClick={handleSubmit}
              >
                Link My ABHA
              </button>
            </div>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default CreateAbhaAddress;

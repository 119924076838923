import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ABhaServices } from "../../redux/services/index";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import AadhaarOtp from "../aadhaarotp/AadhaarOtp";

// dispatch(ABhaServices.AdharOtpApi({aadharNo, navigate}));
const Aadhaar = ({ onNavigate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { mutate: generateOtp, isLoading: addLoading } = useMutation((data) =>
  //   dispatch(ABhaServices.AdharOtpApi(data, onNavigate))
  // );
  const generateOtp = (data) => {
    dispatch(ABhaServices.AdharOtpApi(data, onNavigate));
    console.log(data?.aadharNo)
};
  const [checkbox1Checked, setCheckbox1Checked] = useState(false);
  const [checkbox2Checked, setCheckbox2Checked] = useState(false);
  const [inputs, setInputs] = useState({
    input1: "",
    input2: "",
    input3: "",
  });
  // var aadharInput = inputs.input1 + inputs.input2 + inputs.input3;
  // console.log(aadharInput)
  useEffect(() => {
    dispatch(ABhaServices.AccessTokenApi({}));
    return () => {};
  }, []);

  const handleCheckbox1Change = (event) => {
    setCheckbox1Checked(event.target.checked);
  };

  const handleCheckbox2Change = (event) => {
    setCheckbox2Checked(event.target.checked);
  };

  const handleInputChange = (event, inputId) => {
    const { value } = event.target;
    setInputs((prevState) => ({
      ...prevState,
      [inputId]: value,
    }));
  };

  const moveToNextInput = (nextInputId) => {
    const currentInput = document.activeElement;
    if (currentInput.value.length === 4) {
      document.getElementById(nextInputId).focus();
    }
  };
  const handleBackspace = (event, prevInputId) => {
    const currentInput = document.activeElement;
    if (currentInput.value === "" && event.key === "Backspace") {
      document.getElementById(prevInputId).focus();
    }
  };
  const isVerifyButtonDisabled =
    inputs.input1 === "" ||
    inputs.input2 === "" ||
    inputs.input3.length < 4 ||
    !checkbox1Checked ||
    !checkbox2Checked;
  const handleContinue = () => {
    let aadharInput = inputs.input1 + inputs.input2 + inputs.input3;
    generateOtp({ aadharNo: aadharInput });
    // onNavigate("aadhaarotp");
  };
  const openenterabha = () => {
    onNavigate("enterabha");
  };

  return (
    <div className=" main mt-4">
      <div className="wrapper m-auto lg:w-[40%] md:w-[50%] w-[90%] border-0">
        <h1 className=" text-[32px] font-semibold">
          Enter Aadhaar Number To Create/Link ABHA
        </h1>
        <p className=" pt-4">
          {" "}
          OTP Number Will Be Sent to the Mobile Number Linked
        </p>

        <div className="aadhar-input flex justify-center pt-5">
          <input
            className=" mr-3"
            type="text"
            maxLength="4"
            pattern="\d{4}"
            title="Please enter 4 digits"
            placeholder="XXXX"
            id="input1"
            onInput={() => moveToNextInput("input2")}
            value={inputs.input1}
            onChange={(event) => handleInputChange(event, "input1")}
            required
          />
          <input
            className=" mr-3"
            type="text"
            maxLength="4"
            pattern="\d{4}"
            title="Please enter 4 digits"
            placeholder="XXXX"
            id="input2"
            onInput={() => moveToNextInput("input3")}
            onKeyDown={(event) => handleBackspace(event, "input1")}
            value={inputs.input2}
            onChange={(event) => handleInputChange(event, "input2")}
            required
          />
          <input
            className=" mr-3"
            type="text"
            maxLength="4"
            pattern="\d{4}"
            title="Please enter 4 digits"
            placeholder="XXXX"
            id="input3"
            onKeyDown={(event) => handleBackspace(event, "input2")}
            value={inputs.input3}
            onChange={(event) => handleInputChange(event, "input3")}
            required
          />
        </div>

       

        <div className="pt-5 flex items-center">
          <label className="pt-5 flex items-center">
            <input type="checkbox" onChange={handleCheckbox1Change} />
            <p className="pl-3 mb-[5px]">
              I agree to{" "}
              <span className="text-[#3836a8] font-semibold">
                Terms and Conditions
              </span>{" "}
              and{" "}
              <span className="text-[#3836a8] font-semibold">
                Privacy Policy
              </span>{" "}
              ABYM Technology To Create/Link My ABHA Address
            </p>
          </label>
        </div>

        <div className="pt-5 flex">
          <label className="pt-5 flex items-center">
            <input type="checkbox" onChange={handleCheckbox2Change} />
            <p className="pl-3 mb-[5px]">
              I agree to Terms and Conditions Approved by NHA
            </p>
          </label>
        </div>

        <NavLink>
          <div className=" pt-5">
            <button
              onClick={handleContinue}
              className={`w-[100%] py-3 rounded-lg ${
                isVerifyButtonDisabled
                  ? "bg-gray-400 text-white cursor-not-allowed"
                  : "bg-[#065885]  text-white"
              }`}
              disabled={isVerifyButtonDisabled}
            >
              CONTINUE
            </button>
          </div>
        </NavLink>
        <NavLink onClick={openenterabha}>
          <p className="pt-4 text-[#065885] font-semibold">
            {" "}
            I Already Have ABHA Number / ABHA Address
          </p>
        </NavLink>
      </div>
    </div>
  );
};

export default Aadhaar;

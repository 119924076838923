import axios from "axios";
import API_URL from "../../constant/urls";
import { toast } from "react-toastify";
import {GetToken} from '../../constant/utils'
import CryptoJS from 'crypto-js';
import { jwtDecode, InvalidTokenError } from 'jwt-decode';

// const token = GetToken()
// const decodedToken = jwtDecode(token);
// const Api = axios.create({
//   baseURL: API_URL.API_BASEURL,
// })

const token = GetToken();

let decodedToken;
try {
  decodedToken = token ? jwtDecode(token) : null;
} catch (error) {
  if (error instanceof InvalidTokenError) {
    toast.error("Invalid token");
    // Handle invalid token error, e.g., redirect to login
  } else {
    toast.error("An error occurred while decoding the token");
  }
}

const Api = axios.create({
  baseURL: API_URL.API_BASEURL,
});

Api.interceptors.request.use((request) => {
  request.headers["Access-Token"] = decodedToken?.accessToken
  // request.headers["Token"] = token ? token : "61615c61-cf3b-4532-881e-5334abb626d7-486207b3-3b10-4866-8c35-cf96d5050373"
  if (request?.url == 'v1/auth/sdk/get-access-token'){
        request.headers["token"] ="61615c61-cf3b-4532-881e-5334abb626d7-486207b3-3b10-4866-8c35-cf96d5050373";
  }else{
        request.headers["token"] = token
}
  return request
}, (error) => {
  
  return Promise.reject(error);
})

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default Api;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { ABhaServices } from "../../redux/services/index";

const VerifyMobile = ({onNavigate}) => {

    const dispatch = useDispatch();

    const generateOtp = (data) => {
        dispatch(ABhaServices.MobileOtpApi(data, onNavigate));
    };

  const [phoneNumber, setPhoneNumber] = useState('');
    const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(true);
    

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setPhoneNumber(inputValue);
        setIsVerifyButtonDisabled(inputValue.length !== 10);
    };

    const handleSubmit = () => {
        console.log('Form submitted with phone number:', phoneNumber);
        generateOtp({ mobile: phoneNumber });
        // onNavigate("verifyotp");
    };

  return (
    <>
        <div className=' main mt-4'>
                <div className='wrapper m-auto lg:w-[40%] md:w-[50%] w-[90%] border-0'>
                    <h1 className=' text-[32px] font-semibold'>Link your mobile number  </h1>
                    {/* <p className=' pt-4'> Verify with your mobile number </p> */}

                    <div className="form-group form-group-style customer-phone pt-5">
                        <select name="branche" id="branche">
                            <option id="select" value="select" disabled="" selected=""> +91 </option>
                        </select>
                        <input  value={phoneNumber} onChange={handleInputChange} className=" w-[58%] md:w-[80%] form-control number ng-pristine ng-invalid is-invalid ng-touched" type="text" id="CustomerPhone" placeholder="0000000000" name="number" maxlength="10" minlength="10" formcontrolname="CustomerPhone" />

                    </div>
                
                 <NavLink>
                    <div className=' pt-5'>
                        <button onClick={handleSubmit} className={`w-[100%] py-3 rounded-lg ${isVerifyButtonDisabled ? 'bg-gray-400 text-white cursor-not-allowed' : 'bg-[#065885] text-white'}`} disabled={isVerifyButtonDisabled}>CONTINUE</button>
                    </div>
                    </NavLink>
                </div>
            </div>
    </>
  )
}

export default VerifyMobile
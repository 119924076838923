import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ABhaServices } from "../../redux/services/index";

const LinkAbha = ({onNavigate} ) => {
  const dispatch = useDispatch();
  const generateMobOtp = (data) => {
    dispatch(ABhaServices.AuthMobileOtpApi(data, onNavigate));
};
  const abhaCardDetail = useSelector(state => state.home.retrieveByHelathIdNo);
  const [isConsent1Checked, setIsConsent1Checked] = useState(false);
  const [isConsent2Checked, setIsConsent2Checked] = useState(false);
  const [authMode, setAuthMode] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(true); // Initialize button state to true

  const handleAuthModeChange = (event) => {
    setAuthMode(event.target.value);
    setIsButtonEnabled(
      isConsent1Checked && isConsent2Checked && event.target.value !== ""
    ); // Update button state
  };

  const handleConsent1Change = (event) => {
    setIsConsent1Checked(event.target.checked);
    setIsButtonEnabled(
      event.target.checked && isConsent2Checked && authMode !== ""
    ); // Update button state
  };

  const handleConsent2Change = (event) => {
    setIsConsent2Checked(event.target.checked);
    setIsButtonEnabled(
      isConsent1Checked && event.target.checked && authMode !== ""
    ); // Update button state
  };

  const handleVerify = () => {
    // Check which authentication mode is selected
    if (authMode === "MOBILE_OTP") {
      generateMobOtp({ authMethod: authMode });
        onNavigate('authverifyotp');
    } else if (authMode === "AADHAAR_OTP") {
      generateMobOtp({ authMethod: authMode });
        onNavigate('authverifyotp');
    } else {
      // Handle case where no authentication mode is selected
      console.error("Please select an authentication mode.");
    }
  };

  return (
    <>
      <div className=" main mt-4">
        <div
          className="wrapper m-auto lg:w-[40%] md:w-[50%] w-[90%] border-0"
          id="createabha"
        >
          <h1 className=" text-[32px] font-semibold pb-2">
            ABHA Number / ABHA address{" "}
          </h1>

          <hr />

          <p className=" pb-3">
            <strong>Name:</strong>&nbsp;<span>{abhaCardDetail?.result?.name}</span>
          </p>

          <p className=" pb-3">
            <strong>ABHA Number:</strong>&nbsp;<span>{abhaCardDetail?.result?.healthIdNumber}</span>
          </p>

          <p className=" pb-3">
            <strong>ABHA address:</strong>&nbsp;<span>{abhaCardDetail?.result?.healthId}</span>
          </p>

          <div className="con-input">
            <label htmlFor="consent1">
              <input
                type="checkbox"
                name="consent1"
                id="consent1"
                onChange={handleConsent1Change}
              />
              &nbsp;Confirm &amp; continue with link now
            </label>
          </div>

          <div className="con-input">
            <label htmlFor="consent2">
              <input
                type="checkbox"
                name="consent2"
                id="consent2"
                onChange={handleConsent2Change}
              />
              &nbsp;I agree to give my consent to ABYM Technology to link and
              update my details as per ABHA ID
            </label>
          </div>

          <div className="con-input mt-5">
            <div className="mb-2">
              <h3 className=" text-[24px]">Choose Authentication Method</h3>
            </div>
            <input
              type="radio"
              name="authMode"
              id="authMode"
              value="MOBILE_OTP"
              onChange={handleAuthModeChange}
            />
            &nbsp;<label htmlFor="authMode">Verify via Mobile Number</label>{" "}
            &nbsp;
            <br />
            <input
              type="radio"
              name="authMode"
              id="authMode2"
              value="AADHAAR_OTP"
              onChange={handleAuthModeChange}
            />
            &nbsp;<label htmlFor="authMode2">Verify via Aadhaar Number</label>
          </div>

          {/* Conditional rendering based on selected authMode */}
          {authMode && (
            <NavLink
            //   to={authMode === "MOBILE_OTP" ? "/verifymobile" : "/aadhaar"}
            >
              <div className=" pt-5 flex justify-between">
                <button
                  className={`w-[100%] py-3 rounded-lg ${
                    !isButtonEnabled
                      ? "bg-gray-400 text-white cursor-not-allowed"
                      : "bg-[#065885] text-white"
                  }`}
                  disabled={!isButtonEnabled}
                  onClick={handleVerify}
                >
                  VERIFY
                </button>
              </div>
            </NavLink>
          )}
        </div>
      </div>
    </>
  );
};

export default LinkAbha;

import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    accessToken: [], 
    adharOtp: [], 
    resendAdharOtp: [],  
    verifyAdharOtp: [],
    txnId: null,  
    mobileOtp: [], 
    verifyMobileOtp: [], 
    adharNumber: [], 
    mobileNumber: [], 
    createAbhaAddress: [], 
    abhaCard: [], 
    retrieveByHelathIdNo: [], 
    authMobileOtp: [], 
    authOtpVerify: [],
    profiledata: [],
    updateAbhaDetail: [],
}

export const abhaSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setAccessToken: (state, action) => {
            state.accessToken = action.payload
        },
        setAdharOtp: (state, action) => {
            state.adharOtp = action.payload
        },
        setResendAdharOtp: (state, action) => {
            state.resendAdharOtp = action.payload
        },
        setVerifyAdharOtp: (state, action) => {
            state.verifyAdharOtp = action.payload
        },
        setTxnid: (state, action) => {
            state.txnId = action.payload;
        },
        setMobileOtp: (state, action) => {
            state.mobileOtp = action.payload;
        },
        setMobileVerifyOtp: (state, action) => {
            state.verifyMobileOtp = action.payload;
        },
        setaadhar: (state, action) => {
            state.adharNumber = action.payload;
        },
        setMobileNumber: (state, action) => {
            state.mobileNumber = action.payload;
        },
        setCreateAbhaAddress: (state, action) => {
            state.createAbhaAddress = action.payload;
        },
        setAbhaCard: (state, action) => {
            state.abhaCard = action.payload;
        },
        setRetrieveByHelathIdNo: (state, action) => {
            state.retrieveByHelathIdNo = action.payload;
        },
        setAuthMobileOtp: (state, action) => {
            state.authMobileOtp = action.payload;
        },
        setAuthOtpVerify: (state, action) => {
            state.authOtpVerify = action.payload;
        },
        setProfile: (state, action) => {
            state.profiledata = action.payload;
        },
        SetUpdateAbhaDetail: (state, action) => {
            state.updateAbhaDetail = action.payload;
        },

    },
})

export const {setAccessToken, setAdharOtp,setResendAdharOtp, setVerifyAdharOtp, setTxnid, setMobileOtp, setMobileVerifyOtp, setMobileNumber, setCreateAbhaAddress, setaadhar, setAbhaCard, setRetrieveByHelathIdNo, setAuthMobileOtp, setAuthOtpVerify, setProfile, SetUpdateAbhaDetail} = abhaSlice.actions

export default abhaSlice.reducer
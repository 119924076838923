import API_URL from "../../constant/urls";
import {setAccessToken, setAdharOtp, setResendAdharOtp, setVerifyAdharOtp, setTxnid, setMobileOtp, setMobileVerifyOtp, setMobileNumber, setCreateAbhaAddress, setaadhar, setAbhaCard, setRetrieveByHelathIdNo, setAuthMobileOtp, setAuthOtpVerify, setProfile, SetUpdateAbhaDetail} from "../Slices/abhaSlice";
import Api from "./Axiosinterceptor";
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import {toast } from 'react-toastify';
import {SetToken} from '../../constant/utils'


export const AccessTokenApi = () => async (dispatch) => {
    try {
        const uuid = uuidv4();
        const SECRETKEY = '7f989e7193e65e5cea51876264c47248';
        const mobileNumber = '9557457041';
        const decryptData = CryptoJS.AES.encrypt(uuid + '||' + mobileNumber, SECRETKEY).toString();
        const token = "61615c61-cf3b-4532-881e-5334abb626d7-486207b3-3b10-4866-8c35-cf96d5050373"
        const data = {
            RequestId: decryptData
        };
        const res = await Api.post(API_URL.accessTokenSDK, data, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

        if (res.status === 200) {
            dispatch(setAccessToken(res?.data));
            SetToken(res?.data?.result?.token || "");
        }
        return res;
    } catch (err) {
        let errorMessage = "";
        if (err.response?.data?.message) {
            errorMessage = err.response.data.message;
            toast.error(errorMessage);
        }
        return err;
    }
};

export const AdharOtpApi = (data, onNavigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.GET_ADHAR_OTP,data);
        dispatch(setAdharOtp(res?.data));
        dispatch(setaadhar(data.aadharNo));
        onNavigate("aadhaarotp");
            const txnId = res?.data?.result?.txnId;
            dispatch(setTxnid(txnId));

    } catch (err) {
        let errorMessage = "";
        if (err.response?.data?.message) {
            errorMessage = err.response.data.message;
            toast(errorMessage);
        }
        return err;
    }
};
export const ResendAdharOtpApi = (onNavigate) => async (dispatch, getState) => {
    const txnId = getState().home.txnId;
    
    try {
        const payload = {txnId };
        const res = await Api.post(API_URL.GET_RESEND_ADHAR_OTP, payload);
        dispatch(setResendAdharOtp(res?.data));
        onNavigate("aadhaarotp");
    } catch (err) {
        let errorMessage = "";
        if (err.response?.data?.message) {
            errorMessage = err.response.data.message;
            toast(errorMessage);
        }
        return err;
    }
};

export const VerifyAdharOtpApi = (data, onNavigate) => async (dispatch, getState) => {
    const txnId = getState().home.txnId;
    
    try {
        const payload = { ...data, txnId };
        const res = await Api.post(API_URL.GET_VERIFY_ADHAR_OTP, payload);
        dispatch(setVerifyAdharOtp(res?.data));
        onNavigate("verifyaadhaar");
    } catch (err) {
        let errorMessage = "";
        if (err.response?.data?.message) {
            errorMessage = err.response.data.message;
            toast(errorMessage);
        }
        return err;
    }
};

export const MobileOtpApi = (data, onNavigate) => async (dispatch, getState) => {
    console.log("Payload data mobile:", data?.mobile);
    dispatch(setMobileNumber(data.mobile));
    const txnId = getState().home.txnId;
    
    try {
        const payload = { ...data, txnId };
        const res = await Api.post(API_URL.GET_MOBILE_OTP, payload);
        dispatch(setMobileOtp(res?.data));
        onNavigate("verifyotp");
    } catch (err) {
        let errorMessage = "";
        if (err.response?.data?.message) {
            errorMessage = err.response.data.message;
            toast(errorMessage);
        }
        return err;
    }
};
export const MobileVerifyOtpApi = (data, onNavigate) => async (dispatch, getState) => {
    const txnId = getState().home.txnId;
    const mobile = getState().home.mobileNumber;
    
    try {
        const payload = { ...data, txnId , mobile};
        const res = await Api.post(API_URL.GET_VERIFY_MOBILE_OTP, payload);
        dispatch(setMobileVerifyOtp(res?.data));
    } catch (err) {
        let errorMessage = "";
        if (err.response?.data?.message) {
            errorMessage = err.response.data.message;
            toast(errorMessage);
        }
        return err;
    }
};

export const CreateAbhaAddressApi = (data, onNavigate) => async (dispatch, getState) => {
    const txnId = getState().home.txnId;
    const phoneNumber = getState().home.mobileNumber;
    const patientName = getState().home.verifyAdharOtp?.result?.name;
    const healthId = getState().home.verifyAdharOtp?.result?.healthIdNumber;
    const patientDob = getState().home.verifyAdharOtp?.result?.birthdate;
    const Patientabha_number = "72370464147834"
    const referenceType = "patient"
    const platform = "website"
    const platformType = "create_abha_page"
    const version = "1.0"
    
    try {
        const payload = { ...data, txnId, phoneNumber, patientName, healthId, patientDob, Patientabha_number, referenceType,platform,platformType,version};
        const res = await Api.post(API_URL.GET_CREATE_ABHA_ADDRESS, payload);
        dispatch(setCreateAbhaAddress(res?.data));
        await dispatch(AbhaCardApi(onNavigate));
    } catch (err) {
        let errorMessage = "";
        if (err.response?.data?.message) {
            errorMessage = err.response.data.message;
            toast(errorMessage);
        }
        return err;
    }
};


export const AbhaCardApi = (onNavigate) => async (dispatch, getState) => {
    const profileToken = getState().home.createAbhaAddress?.result?.token;
    
    try {
        const payload = {profileToken};
        const res = await Api.post(API_URL.GET_CARD, payload);
        dispatch(setAbhaCard(res?.data));
    } catch (err) {
        let errorMessage = "";
        if (err.response?.data?.message) {
            errorMessage = err.response.data.message;
            toast(errorMessage);
        }
        return err;
    }
};

export const RetrieveByHelathIdNoAPI = (data, onNavigate) => async (dispatch, getState) => {
    const phoneNumber = getState().home.mobileNumber;
    const patientName = getState().home.verifyAdharOtp?.result?.name;
    const healthId = getState().home.verifyAdharOtp?.result?.healthIdNumber;
    const patientDob = getState().home.verifyAdharOtp?.result?.birthdate;
    const referenceType = "patient"
    const platform = "website"
    const platformType = "create_abha_page"
    const version = "1.0"
    
    try {
        const payload = { ...data, phoneNumber, patientName, healthId, patientDob, referenceType,platform,platformType,version};
        const res = await Api.post(API_URL.GET_RETRIEVE_BY_HEALTHIDNO, payload);
        dispatch(setRetrieveByHelathIdNo(res?.data));
    } catch (err) {
        let errorMessage = "";
        if (err.response?.data?.message) {
            errorMessage = err.response.data.message;
            toast(errorMessage);
        }
        return err;
    }
};
export const AuthMobileOtpApi = (data, onNavigate) => async (dispatch, getState) => {
    const healthid = getState().home.retrieveByHelathIdNo?.result?.healthIdNumber;
    
    try {
        const payload = { ...data, healthid};
        const res = await Api.post(API_URL.GET_AUTH_MOB_OTP, payload);
        dispatch(setAuthMobileOtp(res?.data));
    } catch (err) {
        let errorMessage = "";
        if (err.response?.data?.message) {
            errorMessage = err.response.data.message;
            toast(errorMessage);
        }
        return err;
    }
};
export const AuthOtpVerifyApi = (data, onNavigate) => async (dispatch, getState) => {
    const txnId = getState().home.authMobileOtp?.result?.txnId;
    
    try {
        const payload = { ...data, txnId};
        const res = await Api.post(API_URL.GET_AUTH_VERIFY_ADHAR_OTP, payload);
        dispatch(setAuthOtpVerify(res?.data));
        await dispatch(ProfileApi(onNavigate));
    } catch (err) {
        let errorMessage = "";
        if (err.response?.data?.message) {
            errorMessage = err.response.data.message;
            toast(errorMessage);
        }
        return err;
    }
};

export const ProfileApi = (onNavigate) => async (dispatch, getState) => {
    const txnId = getState().home.txnId;
    const phoneNumber = getState().home.mobileNumber;
    const patientName = getState().home.verifyAdharOtp?.result?.name;
    const healthId = getState().home.verifyAdharOtp?.result?.healthIdNumber;
    const patientDob = getState().home.verifyAdharOtp?.result?.birthdate;
    const profileToken = getState().home.authOtpVerify?.result?.token;
    const referenceType = "patient"
    const platform = "website"
    const platformType = "create_abha_page"
    const version = "1.0"
    
    try {
        const payload = {txnId, phoneNumber, patientName, healthId, patientDob, profileToken, referenceType,platform,platformType,version};
        const res = await Api.post(API_URL.GET_PROFILE, payload);
        dispatch(setProfile(res?.data));
        await dispatch(UpdateAbhaDetailApi(onNavigate));
    } catch (err) {
        let errorMessage = "";
        if (err.response?.data?.message) {
            errorMessage = err.response.data.message;
            toast(errorMessage);
        }
        return err;
    }
};
export const UpdateAbhaDetailApi = (onNavigate) => async (dispatch, getState) => {
    const uuid = uuidv4();
    const patientId = uuid;
    const referenceId = uuid;
    const phoneNumber = getState().home.profiledata?.result?.mobile;
    const patientName = getState().home.profiledata?.result?.firstName;
    const abhaAddress = getState().home.profiledata?.result?.healthId;
    const abhaNumber = getState().home.profiledata?.result?.healthIdNumber;
    const patientDob = getState().home.profiledata?.result?.yearOfBirth;
    const referenceType = "patient"
    const platform = "website"
    const platformType = "Link Abha"
    const version = "1.0"
    const isLinked = true
    
    try {
        const payload = {patientId, referenceId, phoneNumber, patientName, abhaAddress, abhaNumber, patientDob, referenceType,platform,platformType,version,isLinked};
        const res = await Api.post(API_URL.GET_UPDATE_ABHA_DETAIL, payload);
        dispatch(SetUpdateAbhaDetail(res?.data));
    } catch (err) {
        let errorMessage = "";
        if (err.response?.data?.message) {
            errorMessage = err.response.data.message;
            toast(errorMessage);
        }
        return err;
    }
};






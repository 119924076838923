// const BASEURL = 'http://abym.digital:8094/api/';
const BASEURL = 'http://103.145.50.194:8094/api/';


const MID = 'api';
const API_URL = {
  API_BASEURL: BASEURL,
  GET_ADHAR_OTP: "v1/abha/sdk/send-aadhar-otp",
  GET_RESEND_ADHAR_OTP: "v1/abha/sdk/resend-aadhar-otp",
  accessTokenSDK:'v1/auth/sdk/get-access-token',
  GET_VERIFY_ADHAR_OTP:'v1/abha/sdk/verify-aadhar-otp',
  GET_MOBILE_OTP:'v1/abha/sdk/send-mobile-otp',
  GET_VERIFY_MOBILE_OTP:'v1/abha/sdk/verify-mobile-otp',
  GET_CREATE_ABHA_ADDRESS:'v1/abha/sdk/create-new-abha',
  GET_CARD:'v1/abha/sdk/account/png/card',
  GET_RETRIEVE_BY_HEALTHIDNO:'v1/abha/sdk/retrieveByHelathIdNo',
  GET_AUTH_MOB_OTP:'v1/abha/sdk/sendV1AuthInit',
  GET_AUTH_VERIFY_ADHAR_OTP:'v1/abha/sdk/confirmAadhaarOtp',
  GET_PROFILE:'v1/abha/sdk/account/profile',
  GET_UPDATE_ABHA_DETAIL:'v1/abha/sdk/update-abha-details',
  
};
export default API_URL;
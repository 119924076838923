import React from 'react'

const Header = () => {
  return (
    <>
         <div id="main_layout">
                <div className="bg-logo">
                    <img src="https://abym.in/assets/img/logo/logo.png"/>
                </div>
            </div>
    </>
  )
}

export default Header
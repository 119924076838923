
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { ABhaServices } from "../../redux/services/index";

const VerifyAadhaar = ({onNavigate}) => {
    const baseURLImg = "data:image/png;base64,";
    const dispatch = useDispatch();
    const handleSubmit = () => {
        onNavigate('verifymobile');
        
    };
    const verifyAdharOtp = useSelector(state => state.home.verifyAdharOtp);
    const adharNumber = useSelector(state => state.home.adharNumber);
    const maskAadharNumber = (aadharNumber) => {
        const lastFourDigits = aadharNumber.slice(-4);
        const maskedPart = "XXXXXXXX";
        const maskedAadharNumber = maskedPart + lastFourDigits;
        return maskedAadharNumber;
      }
    console.log("rael adhar", adharNumber)
    let genderText;
    if (verifyAdharOtp?.result?.gender === 'M') {
      genderText = 'Male';
    } else if (verifyAdharOtp?.result?.gender === 'F') {
      genderText = 'Female';
    } else {
      genderText = 'Unknown';
    }
    return (
        <>
            <div className=' main mt-4' id='adhar-otp'>
                <div className='wrapper m-auto  md:w-[55%] lg:w-[45%] sm:w-[60%] w-[90%] border-0'>
                    <h1 className=' text-[32px] font-semibold'>Confirm your Aadhaar Details</h1>


                    <div class="adhar-img">

                        <div class=" mb-5">
                            <img src="https://www.lalpathlabs.com/assets/abha/img/adhar_head.png" className='h-[60px] w-[100%]' />
                        </div>

                        <div className=' flex '>
                            <div class=" md:px-5 flex justify-center items-center">
                                <img class="aadhar-user-pic" src={`${baseURLImg}${verifyAdharOtp?.result?.photo}`} />
                            </div>
                            <div class=" pl-4">
                                <div class="ap-line">
                                    <span class=" text-[#9e9e9e] pr-3">Name</span>
                                    <span class="a-name">{verifyAdharOtp?.result?.name}</span>
                                </div>
                                <div class="ap-line">
                                    <span class="text-[#9e9e9e] pr-3">Date of Birth</span>
                                    <span class="a-name">{verifyAdharOtp?.result?.birthdate}</span>
                                </div>
                                <div class="ap-line">
                                    <span class="text-[#9e9e9e] pr-3">Gender</span>
                                    <span class="a-name">{genderText}</span>
                                </div>
                                <div class=" text-[22px]">{maskAadharNumber(adharNumber)}</div>
                            </div>
                        </div>

                    </div>

                    <p class="text-center text-[#5A7184]">Verify your Aadhaar Details as these details will be updated to your LPL Patient Profile</p>
  
                        <NavLink>
                    <div className=' pt-5'>
                        <button onClick={handleSubmit} className=' w-[100%] py-3 bg-[#065885] text-white rounded-lg'>PROCEED</button>
                    </div>
                    </NavLink>
                </div>
            </div>
        </>
    )
}


export default VerifyAadhaar
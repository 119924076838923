import { configureStore } from '@reduxjs/toolkit';
import commonSlice from './Slices/commonSlice';
import abhaSlice from './Slices/abhaSlice';

const store = configureStore({
    reducer: {
        common: commonSlice,
        home: abhaSlice,
       },
})
export default store
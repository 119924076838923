import react from "react";

export const SetToken = (access_token) => {
    localStorage.setItem("access_token", access_token);
  
    return true;
  };
  
 export const GetToken = () => {
    const token = localStorage.getItem("access_token");
  
    if (token) {
      return token;
    } else {
      return false;
    }
  };


  
// module.exports = {
//     setToken,
//     getToken,
    
//     // setEmail,
//     // getEmail,
//     // setUserId,
//     // getUserId,
//   };
  
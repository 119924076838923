
import './App.css';
import Header from './pages/Header/Header';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import ConfirmDetails from './pages/confirmdetails/ConfirmDetails';

import CreateProfile from './pages/createprofile/CreateProfile';
import DownloadAbha from './pages/downloadabha/DownloadAbha';
import LinkAbha from './pages/linkabha/LinkAbha';
import VerifyAadhaar from './pages/verifyaadhaar/VerifyAadhaar';
import AadhaarOtp from './pages/aadhaarotp/AadhaarOtp';
import Aadhaar from './pages/aadhaar/Aadhaar';
import VerifyOtp from './pages/verifyotp/VerifyOtp';
import VerifyMobile from './pages/verifymobile/VerifyMobile';
import EnterAbha from './pages/enterabha/EnterAbha';
import Homepage from './pages';



function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>

          <Route path="/" element={<Homepage/>} />
          {/* <Route path="/verifyotp" element={<VerifyOtp/>} />
          <Route path="/aadhaar" element={<Aadhaar/>} />
          <Route path="/verifymobile" element={<VerifyMobile/>} /> 
          <Route path="/enterabha" element={<EnterAbha/>} />
          <Route path="/downloadabha" element={<DownloadAbha/>} />
          <Route path="/linkabha" element={<LinkAbha/>} />
          <Route path="/verifyaadhaar" element={<VerifyAadhaar/>} />
          <Route path="/aadhaarotp" element={<AadhaarOtp/>} /> */}
          
        </Routes>
      </Router>
    </>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ABhaServices } from "../../redux/services/index";

const AuthverifyOtp = ({ onNavigate }) => {
  const dispatch = useDispatch();
  const mobileNo = useSelector(state => state.home.mobileNumber);
  console.log("asdcascas", mobileNo)
  const VerifyByOtp = (data) => {
    dispatch(ABhaServices.AuthOtpVerifyApi(data, onNavigate));
  };

  const [otpTimer, setOtpTimer] = useState(90);
  const [timerRunning, setTimerRunning] = useState(false);
  const [inputs, setInputs] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
  });

  const moveToNextInput = (nextInputId) => {
    const currentInput = document.activeElement;
    if (currentInput.value.length === 1) {
      document.getElementById(nextInputId).focus();
    }
  };

  const handleBackspace = (event, prevInputId) => {
    const currentInput = document.activeElement;
    if (currentInput.value === "" && event.key === "Backspace") {
      document.getElementById(prevInputId).focus();
    }
  };

  const handleInputChange = (event, inputId) => {
    const { value } = event.target;
    setInputs((prevState) => ({
      ...prevState,
      [inputId]: value,
    }));
  };

  useEffect(() => {
    let interval;
    if (timerRunning) {
      interval = setInterval(() => {
        setOtpTimer((prevTime) => {
          if (prevTime === 0) {
            clearInterval(interval);
            setTimerRunning(false);
            return 90; // Reset timer to initial value after reaching 0
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timerRunning]);

  const handleVerifyButtonClick = () => {
    setTimerRunning(true);
    const otpValue = Object.values(inputs).join("");
    VerifyByOtp({ otp: otpValue });
  };

  const isVerifyButtonDisabled = Object.values(inputs).some(
    (value) => value === ""
  );
  const handleClickBack = () => {
    onNavigate('enterabha');
  };

  return (
    <div className=" main mt-4" id="adhar-otp">
      <div className="wrapper m-auto lg:w-[40%] md:w-[50%] w-[90%] border-0">
        <h1 className=" text-[32px] font-semibold">Verify your details</h1>
        <p className=" pt-4">
          {" "}
          Enter OTP sent to your linked mobile number
        </p>

        <div className="aadhar-input  pt-5">
        <input
            className=" mr-3"
            type="text"
            maxLength="1"
            pattern="\d{4}"
            title="Please enter 4 digits"
            placeholder="0"
            id="input1"
            onInput={() => moveToNextInput("input2")}
            value={inputs.input1}
            onChange={(event) => handleInputChange(event, "input1")}
            required
          />
          <input
            className=" mr-3"
            type="text"
            maxLength="1"
            pattern="\d{4}"
            title="Please enter 4 digits"
            placeholder="0"
            id="input2"
            onInput={() => moveToNextInput("input3")}
            onKeyDown={(event) => handleBackspace(event, "input1")}
            value={inputs.input2}
            onChange={(event) => handleInputChange(event, "input2")}
            required
          />
          <input
            className=" mr-3"
            type="text"
            maxLength="1"
            pattern="\d{4}"
            title="Please enter 4 digits"
            placeholder="0"
            id="input3"
            onInput={() => moveToNextInput("input4")}
            onKeyDown={(event) => handleBackspace(event, "input2")}
            value={inputs.input3}
            onChange={(event) => handleInputChange(event, "input3")}
            required
          />

          <input
            className=" mr-3"
            type="text"
            maxLength="1"
            pattern="\d{4}"
            title="Please enter 4 digits"
            placeholder="0"
            id="input4"
            onInput={() => moveToNextInput("input5")}
            onKeyDown={(event) => handleBackspace(event, "input3")}
            value={inputs.input4}
            onChange={(event) => handleInputChange(event, "input4")}
            required
          />

          <input
            className=" mr-3"
            type="text"
            maxLength="1"
            pattern="\d{4}"
            title="Please enter 4 digits"
            placeholder="0"
            id="input5"
            onInput={() => moveToNextInput("input6")}
            onKeyDown={(event) => handleBackspace(event, "input4")}
            value={inputs.input5}
            onChange={(event) => handleInputChange(event, "input5")}
            required
          />

          <input
            className=" mr-3"
            type="text"
            maxLength="1"
            pattern="\d{4}"
            title="Please enter 4 digits"
            placeholder="0"
            id="input6"
            onKeyDown={(event) => handleBackspace(event, "input5")}
            value={inputs.input6}
            onChange={(event) => handleInputChange(event, "input6")}
            required
          />
        </div>
        <div className=" pt-5 flex justify-between">
          <div className=" w-[48%]">
            <NavLink>
              <button onClick={handleClickBack} className=" w-[100%] py-3 bg-[#065885] text-white rounded-lg">
                BACK
              </button>
            </NavLink>
          </div>
          <div className=" w-[48%]">
          <NavLink>
            <button
              onClick={handleVerifyButtonClick}
              className={`w-[100%] py-3 rounded-lg ${
                isVerifyButtonDisabled
                  ? "bg-gray-400 text-white cursor-not-allowed"
                  : "bg-[#065885] text-white"
              }`}
              disabled={isVerifyButtonDisabled}
            >
              VERIFY
            </button>
        </NavLink>
          </div>
        </div>
        <p className=" pt-4 text-[#5a7184] ">
          Don't receive to your number ?{" "}
          <a href="" className=" text-[#0d6efd]">
            Resend
          </a>
        </p>
      </div>
    </div>
  );
};

export default AuthverifyOtp;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ABhaServices } from "../../redux/services/index";
import { useNavigate } from "react-router-dom";

const DownloadAbha = ({ onNavigate }) => {
    const baseURLImg = "data:image/png;base64,";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const abhaCardDetail = useSelector(state => state.home.abhaCard);
  const abhadetails = useSelector(state => state.home.createAbhaAddress);
  console.log("resjgavxhgas data card", abhaCardDetail)
//   useEffect(() => {
//     dispatch(ABhaServices.AbhaCardApi(onNavigate));
//     return () => {};
//   }, []);
const downloadImage = () => {
    const link = document.createElement('a');
    link.href = `${baseURLImg}${abhaCardDetail?.result}`;
    link.download = 'YourAbhaCard.png'; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <div className=" main mt-4" id="adhar-otp">
        <div className="wrapper m-auto  md:w-[55%] lg:w-[45%] sm:w-[60%] w-[90%] border-0">
          <h1 className=" text-[24px] md:text-[32px] font-semibold">
            ABHA Health Card Created / Linked Successfully
          </h1>

          <div className=" text-center py-4">
            <img src={`${baseURLImg}${abhaCardDetail?.result}`} className=" w-[100%] m-auto" alt="" />
          </div>

          <div class=" text-center">
            <p>
              Thank you for Submitting the details. Your ABHA Address is created
              / linked successfully.{" "}
              <span className=" text-[#014899] font-semibold">
              {abhadetails?.result?.healthId}
              </span>
            </p>
          </div>

          <div className=" pt-5">
          <button className="w-[100%] py-3 bg-[#065885] text-white rounded-lg" onClick={downloadImage}>
          Download Your Abha card
        </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadAbha;

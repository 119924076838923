
import React, { useState,useEffect } from 'react'
import Aadhaar from './aadhaar/Aadhaar';
import AadhaarOtp from './aadhaarotp/AadhaarOtp';
import DownloadAbha from './downloadabha/DownloadAbha';
import LinkAbha from './linkabha/LinkAbha';
import VerifyAadhaar from './verifyaadhaar/VerifyAadhaar';
import VerifyOtp from './verifyotp/VerifyOtp';
import VerifyMobile from './verifymobile/VerifyMobile';
import EnterAbha from './enterabha/EnterAbha';
import CreateAbhaAddress from './createAbhaAddress/createAbhaAddress';
import AuthverifyOtp from './authVerifyotp/VerifyOtp';

// dispatch(ABhaServices.AdharOtpApi({aadharNo, navigate}));
const Homepage = () => {
    const [currentComponent, setCurrentComponent] = useState('aadharform')

    const handleNavigation = (component) => {
        setCurrentComponent(component);
      };
 
  return (
    <div className=' main mt-4'>
      <div>
      {currentComponent === 'aadharform' && <Aadhaar onNavigate={handleNavigation} />}
      {currentComponent === 'aadhaarotp' && <AadhaarOtp onNavigate={handleNavigation} />}
      {currentComponent === 'verifymobile' && <VerifyMobile onNavigate={handleNavigation} />}
      {currentComponent === 'enterabha' && <EnterAbha onNavigate={handleNavigation} />}
      {currentComponent === 'downloadabha' && <DownloadAbha onNavigate={handleNavigation} />}
      {currentComponent === 'linkabha' && <LinkAbha onNavigate={handleNavigation} />}
      {currentComponent === 'verifyaadhaar' && <VerifyAadhaar onNavigate={handleNavigation} />}
      {currentComponent === 'verifyotp' && <VerifyOtp onNavigate={handleNavigation} />}
      {currentComponent === 'createabhaaddress' && <CreateAbhaAddress onNavigate={handleNavigation} />}
      {currentComponent === 'authverifyotp' && <AuthverifyOtp onNavigate={handleNavigation} />}
    </div>
    </div>

  )
}

export default Homepage